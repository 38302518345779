<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>
<script>
import Default from "@/layouts/Default";

const DEFAULT_LAYOUT = 'Default';

export default {
  computed: {
    layout() {
      return DEFAULT_LAYOUT;
      // return this.$route.meta.layout || DEFAULT_LAYOUT;
    }
  },
  components: {Default}
};
</script>
