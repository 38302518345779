import Vue from 'vue'
import App from './App.vue'
import router from './router';
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import VueApollo from 'vue-apollo'
import apolloClient from './apollo';

Vue.use(VueApollo)
Vue.use(Buefy)
Vue.config.productionTip = false

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})
new Vue({
  router,
  apolloProvider,
  render: h => h(App),
}).$mount('#app')
