import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../pages/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/rozwiaz-test/:token',
    name: 'resolve-test',
    meta: {layout: 'Default'},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/pages/ResolveTestView.vue'),
    props: (router) => {
      return {
        token: router.params.token,
      };
    },
  },
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/404',
    name: '404',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "general" */ '../pages/404.vue'),
  },

];

const routerInstance = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default routerInstance;
