import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

const uri = process.env.GRAPHQL_ENDPOINT || 'https://picador.cwko.pl/graphql';
// HTTP connection to the API
const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri,
    defaultOptions: {
        $query: {
            errorPolicy: 'all',
            // fetchPolicy: 'cache-and-network',
        },
    },
    errorHandler(error) {
        handleGqlError(error);

        loader.dec();
        // tslint:disable-next-line
        console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message);
        // tslint:disable-next-line
        console.log(error);
    },
    // fetchOptions: {
    //     mode: 'no-cors',
    // },
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
})

export default apolloClient;