<template>
  <div class="container">
     <slot/>
     <footer class="footer">
       <div class="content has-text-centered">
         <p>
           <strong>Test Platform</strong> by <a href="https://cwko.pl">CWKO</a>
         </p>
       </div>
     </footer>
   </div>
        
</template>

<script>
    export default {
    }
</script>
